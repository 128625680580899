.projects-3 .card-blog {
  min-height: unset;
}

.text-gradient-primary {
background: -webkit-linear-gradient(
    0deg,
    #ffa745 0%,
    #fe869f 30%,
    #ef7ac8 45%,
    #a083ed 70%,
    #43aeff 85%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.4;
}

.card.card-blog {
  min-height: 300px;
}

.navbar-transparent {
    padding-top: 2px;
}

.navbar {
    padding: 2px;
}

.footer {
    background: #222a42 !important;
}

.our-services,
.our-projects,
.for-section {
    background-color: #2d234247;
}

.text-white {
    color: #fff !important;
}

